import { ServerCrash } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { Skeleton } from '~/components/ui/skeleton'

type LazyImageProps = {
  /** URL of the image to display. If not provided, the skeleton screen is displayed. */
  src?: string
  /** Alt text for the image. */
  alt?: string
  /** Title text for the image. */
  title?: string
  /** Width of the image in pixels. */
  width: number
  /** Height of the image in pixels. */
  height: number
  /** Duration of the image animation in milliseconds. */
  transitionDuration?: number
  /** Class name to apply to the img tag, skeleton, and error div e.g. 'rounded-lg hover:ring-1 hover:ring-primary' */
  className?: string
  /** Style to apply to the img tag, skeleton, and error div. */
  style?: React.CSSProperties
  /** The loading attribute of the img tag. */
  loading?: 'lazy' | 'eager'
}

/**
 * Displays an image with a skeleton screen as a placeholder until the image is fully loaded.
 * This is useful for improving the user experience by providing
 * a visual cue that content is loading, especially for slow-loading images.
 * - If the image fails to load, a placeholder with an error icon is displayed instead.
 * - If `src` is `undefined`, the skeleton screen is displayed.
 */
export function LazyImage({
  src,
  alt,
  title,
  width,
  height,
  transitionDuration = 500,
  className,
  style,
  loading = 'lazy',
}: LazyImageProps) {
  const [state, setState] = useState<'loading' | 'loaded' | 'error'>('loading')
  const imageRef = useRef<HTMLImageElement>(null)

  // onLoad() is not called if the image is already loaded when the component is mounted.
  // that's why we need to manually trigger setState('loaded') if the image is already loaded.
  useEffect(() => {
    if (imageRef.current && imageRef.current.complete) {
      setState('loaded')
    } else {
      setState('loading')
    }
  }, [src])

  const aspectRatio = `${width} / ${height}`
  className = className ?? ''
  style = style ?? {}

  return (
    <div className={`max-w-full`} style={{ aspectRatio }}>
      {src && state !== 'error' && (
        <img
          ref={imageRef}
          src={src}
          alt={alt}
          title={title}
          loading={loading}
          className={
            // hide the image while loading
            `${state === 'loading' ? 'invisible h-0 w-0' : 'visible h-auto w-full'} ` +
            // animation for fading in the image
            `transition-opacity ${state === 'loading' ? 'opacity-0' : 'opacity-100'} ` +
            // extra class name
            className
          }
          style={{
            ...style,
            transitionDuration: `${transitionDuration}ms`,
            animationDuration: `${transitionDuration}ms`,
          }}
          onLoad={() => setState('loaded')}
          onError={() => setState('error')}
        />
      )}
      {/* do not render the skeleton above the image because the image needs to be in viewport for it to load. if you render skeleton above the image, users will have to scroll down a lot before the browser will load the image. */}
      {(!src || state === 'loading') && (
        <Skeleton className={`h-full w-full ${className}`} style={style} />
      )}
      {state === 'error' && (
        <div
          className={`flex h-full w-full items-center justify-center bg-gray-200 ${className}`}
          style={style}
          title="Failed to load image"
        >
          <ServerCrash className="h-8 w-8 text-gray-400" />
        </div>
      )}
    </div>
  )
}
